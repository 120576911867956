import { Fragment, useEffect, useRef, useState } from 'react';

import Link from 'next/link';

import { Menu, Transition } from '@headlessui/react';
import { constants } from 'constants.mjs';

export default function NavDropdown({ dropDownData }: any) {
  return (
    <div className='relative z-[5000] text-left lg:text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex w-full items-center justify-center rounded-md bg-opacity-20 py-2 text-sm font-medium text-white hover:bg-opacity-30 hover:text-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            {dropDownData.title}
            <svg
              className='h-5 w-5 md:ml-[1px]'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
            >
              <path
                fillRule='evenodd'
                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute z-[10000] mt-2 min-w-[200px] max-w-none origin-top-right divide-y divide-gray-100 rounded-md bg-brand-green shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1'>
              {dropDownData.items.map((item: any, index: number) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <Link href={constants.docsPath + item.link}>
                      <button
                        className={`${
                          active ? 'bg-brand-darker-green text-white' : 'text-white'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {/* {active ? (
                        <EditActiveIcon
                          className="w-5 h-5 mr-2"
                          aria-hidden="true"
                        />
                      ) : (
                        <EditInactiveIcon
                          className="w-5 h-5 mr-2"
                          aria-hidden="true"
                        />
                      )} */}
                        {item.title}
                      </button>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function EditInactiveIcon(props: any) {
  return (
    <svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4 13V16H7L16 7L13 4L4 13Z' fill='#EDE9FE' stroke='#A78BFA' strokeWidth='2' />
    </svg>
  );
}

function EditActiveIcon(props: any) {
  return <path d='M4 13V16H7L16 7L13 4L4 13Z' fill='#8B5CF6' stroke='#C4B5FD' strokeWidth='2' />;
}
