import { Children, createContext, useContext, useEffect, useRef, useState } from 'react';

import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { Tag } from '@/components/mdx/Tag';
import { CodeProps, LangType, ScriptProperties } from '@/components/mdx/types';

import { usePreferredLanguage } from '@/store/code';

const getPanelTitle = ({ language }: { language: LangType }) => {
  return language ?? 'Code';
};

const ClipboardIcon = ({ classNaming }: { classNaming: string }) => {
  return (
    <svg viewBox='0 0 20 20' aria-hidden='true' className={classNaming}>
      <path
        strokeWidth='0'
        d='M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z'
      />
      <path
        fill='none'
        strokeLinejoin='round'
        d='M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1'
      />
    </svg>
  );
};

const CopyButton = ({ code }: { code: string }) => {
  const [copyCount, setCopyCount] = useState(0);
  const copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      const timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      type='button'
      className={clsx(
        'group/button absolute right-4 top-3.5 overflow-hidden rounded-full py-1 pl-2 pr-3 text-2xs font-medium backdrop-blur transition lg:opacity-0 lg:focus:opacity-100 lg:group-hover:opacity-100',
        copied
          ? 'bg-emerald-400/10 ring-1 ring-inset ring-emerald-400/20'
          : 'bg-white/5 hover:bg-white/7.5 dark:bg-white/2.5 dark:hover:bg-white/5',
      )}
      onClick={() => {
        window.navigator.clipboard.writeText(code).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      <span
        aria-hidden={copied}
        className={clsx(
          'pointer-events-none flex items-center gap-0.5 text-zinc-400 transition duration-300',
          copied && '-translate-y-1.5 opacity-0',
        )}
      >
        <ClipboardIcon classNaming='h-5 w-5 fill-zinc-500/20 stroke-zinc-500 transition-colors group-hover/button:stroke-zinc-400' />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={clsx(
          'pointer-events-none absolute inset-0 flex items-center justify-center text-emerald-400 transition duration-300',
          !copied && 'translate-y-1.5 opacity-0',
        )}
      >
        Copied!
      </span>
    </button>
  );
};

const CodeHeaderForTagAndLabel = ({ tag, label }: Pick<CodeProps, 'tag' | 'label'>) => {
  if (!tag && !label) {
    return null;
  }

  return (
    <div className='flex h-9 items-center gap-2 border-y border-b-white/7.5 border-t-transparent bg-white/2.5 px-4 dark:border-b-white/5'>
      {tag && (
        <div className='dark flex'>
          <Tag variant='small'>{tag}</Tag>
        </div>
      )}
      {tag && label && <span className='h-0.5 w-0.5 rounded-full bg-zinc-500' />}
      {label && <span className='font-mono text-xs text-zinc-400'>{label}</span>}
    </div>
  );
};

const SingleCodeBlockWithTagAndLabelHeader = (props: CodeProps) => {
  const child = Children.only(props.children);
  return (
    <div className='group dark:bg-white/2.5'>
      <CodeHeaderForTagAndLabel {...props} />
      <div className='relative'>
        <pre className='overflow-x-auto p-4 text-xs text-white'>{props.children}</pre>
        <CopyButton
          code={child.props.code ?? props.code ?? '<🙂>The code you tried to copy was empty</🙂>'}
        />
      </div>
    </div>
  );
};

const CodeTabs = ({
  title,
  children,
  selectedIndex,
}: {
  title: string;
  children: JSX.Element;
  selectedIndex: number;
}) => {
  const hasMoreThanOneChildren = Children.count(children) > 1;

  if (!title && !hasMoreThanOneChildren) {
    return null;
  }

  return (
    <div className='flex min-h-[calc(theme(spacing.12)+1px)] w-full flex-wrap items-start gap-x-4 border-b border-zinc-700 px-4 dark:border-zinc-800'>
      {title && <h3 className='mr-auto pt-3 text-xs font-semibold text-white'>{title}</h3>}
      {hasMoreThanOneChildren && (
        <Tab.List className='-mb-px ml-auto flex w-full flex-wrap gap-4 py-3 text-xs font-medium'>
          {Children.map(children, (child, childIndex) => (
            <Tab
              className={clsx(
                'border-b transition focus:[&:not(:focus-visible)]:outline-none',
                childIndex === selectedIndex
                  ? 'border-primary-500 text-primary-400'
                  : 'border-transparent text-zinc-400 hover:text-zinc-300',
              )}
            >
              {getPanelTitle(child.props)}
            </Tab>
          ))}
        </Tab.List>
      )}
    </div>
  );
};

const CodeBlockGroupWithTagAndLabelHeader = (props: CodeProps) => {
  const hasMoreThanOneChildren = Children.count(props.children) > 1;
  if (hasMoreThanOneChildren) {
    return (
      <Tab.Panels>
        {Children.map(props.children, (child) => (
          <Tab.Panel>
            <SingleCodeBlockWithTagAndLabelHeader {...props}>
              {child}
            </SingleCodeBlockWithTagAndLabelHeader>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    );
  }

  return (
    <SingleCodeBlockWithTagAndLabelHeader {...props}>
      {props.children}
    </SingleCodeBlockWithTagAndLabelHeader>
  );
};

const CodeGroupContext = createContext(false);

export const CodeGroup = (props: CodeProps) => {
  const languages = Children.map(props.children, (child) => getPanelTitle(child.props));
  const { preferred_language, setPreferredLanguage } = usePreferredLanguage();

  const hasMoreThanOneChildren = Children.count(props.children) > 1;
  const Container = hasMoreThanOneChildren ? (Tab.Group as any) : 'div';
  const [localLangSelection, setLocalLangSelection] = useState<LangType>(languages[0]);

  useEffect(() => {
    if (languages.indexOf(preferred_language) === -1) return;
    else setLocalLangSelection(preferred_language);
  }, [languages, preferred_language]);

  const currentLanguageIndex = languages.indexOf(localLangSelection);

  const containerProps = hasMoreThanOneChildren
    ? {
        as: 'div',
        selectedIndex: currentLanguageIndex,
        onChange: (newSelectedIndex: number) => {
          setPreferredLanguage({
            preferredLanguage: languages[newSelectedIndex],
          });
        },
      }
    : {};

  return (
    <CodeGroupContext.Provider value={true}>
      <Container
        {...containerProps}
        className='not-prose my-6 overflow-hidden rounded-2xl bg-brand-dark shadow-md dark:ring-1 dark:ring-white/10'
      >
        <CodeTabs
          title={props.title}
          selectedIndex={hasMoreThanOneChildren ? currentLanguageIndex : 0}
        >
          {props.children}
        </CodeTabs>
        <CodeBlockGroupWithTagAndLabelHeader {...props}>
          {props.children}
        </CodeBlockGroupWithTagAndLabelHeader>
      </Container>
    </CodeGroupContext.Provider>
  );
};

export const Pre = (props: CodeProps) => {
  const isGrouped = useContext(CodeGroupContext);

  if (isGrouped) {
    return props.children;
  }

  return <CodeGroup {...props}>{props.children}</CodeGroup>;
};

export const Code = ({ children }: { children: string }) => {
  const isGrouped = useContext(CodeGroupContext);
  let codeReference: HTMLElement | null = null;
  let handler: { (): void }[] = [];
  const copyCodeLine = (child: ChildNode) => {
    window.navigator.clipboard.writeText(
      child.textContent || '<🙂>The code you tried to copy was empty</🙂>',
    );
  };

  const copyCode = (content: string) => {
    window.navigator.clipboard.writeText(
      content || '<🙂>The code you tried to copy was empty</🙂>',
    );
  };

  const letUserKnowTheyCanCopySingleCodeLine = () => {
    if (codeReference)
      for (let index = 0; index < codeReference.children.length; index++) {
        if (codeReference?.children.item(index)?.childNodes.length) {
          const element = codeReference?.children.item(index);
          element?.classList.add('copy-snippet');
        }
      }
  };
  if (isGrouped) {
    return (
      <code
        onMouseEnter={() => {
          letUserKnowTheyCanCopySingleCodeLine();
          // attach click handlers when user mouse-over code snippets so user can copy code lines
          codeReference?.childNodes.forEach((child: ChildNode, index) => {
            if (child.hasChildNodes()) {
              child.addEventListener(
                'click',
                (handler[index] = () => {
                  copyCodeLine(child);
                }),
              );
            }
          });
        }}
        onMouseLeave={() => {
          // Remove listeners so we don't fry eggs with user's CPU
          codeReference?.childNodes.forEach((child: ChildNode, index) => {
            if (child.hasChildNodes()) {
              child.removeEventListener('click', handler[index]);
            }
          });
        }}
        ref={(codeRef) => {
          codeReference = codeRef;
        }}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  } else {
    return (
      <code
        ref={codeReference}
        className='copy-snippet' // Optional: style for indicating copy ability
        onClick={() => copyCode(children)}
      >
        {children}
      </code>
    );
  }
};

// Note: This func can be improved e.g using DOMPurify to sanitize attr's && verifying src val for potentially harmful urls

export const UseInjectScript = ({
  properties,
  attributes = {},
}: {
  properties: ScriptProperties;
  attributes: { [key: string]: string };
}) => {
  const isScriptLoaded = useRef(false);
  let containerRef: HTMLElement | null = null;

  useEffect(() => {
    if (!isScriptLoaded.current) {
      const script = document.createElement('script');
      Object.assign(script, properties);
      for (const [key, value] of Object.entries(attributes)) {
        script.setAttribute(key, value);
      }
      containerRef?.appendChild(script);
      isScriptLoaded.current = true;
    }
    return () => {
      if (containerRef && isScriptLoaded.current) {
        containerRef.replaceChildren('');
        // containerRef.removeChild()
        isScriptLoaded.current = false;
      }
    };
  }, [attributes, containerRef, properties]);
  return (
    <div
      ref={(elem) => {
        containerRef = elem;
      }}
    ></div>
  );
};
