export function getTimeSince(dataISOString: string) {
  if (!dataISOString) return;
  const now: Date = new Date();
  const date: Date = new Date(dataISOString);

  const timeDiff: number = now.getTime() - date.getTime();
  const seconds: number = Math.floor(timeDiff / 1000);
  const minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);
  const days: number = Math.floor(hours / 24);
  const years: number = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 365) {
    return `${days} days ago`;
  } else {
    return `${years} years ago`;
  }
}

export function humanizeTime(dateISOstring: string): string {
  const timestamp = new Date(dateISOstring);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - timestamp.getTime()) / 1000);
  const intervals = [
    { name: 'decade', seconds: 315360000 },
    { name: 'year', seconds: 31536000 },
    { name: 'month', seconds: 2592000 },
    { name: 'day', seconds: 86400 },
    { name: 'hour', seconds: 3600 },
    { name: 'minute', seconds: 60 },
  ];

  if (seconds < 60) {
    return 'just now';
  }

  for (const interval of intervals) {
    const count = Math.floor(seconds / interval.seconds);
    if (count > 0) {
      return `${count} ${interval.name}${count > 1 ? 's' : ''} ago`;
    }
  }

  return '';
}
