import { useEffect, useState } from 'react';

import Link from 'next/link';

// import { SettingsDialog } from "./SettingsDialog";
// import { ThemeSelector } from "./ThemeSelector";
// import Tooltip from "./Tooltip";
import clsx from 'clsx';
import { constants } from 'constants.mjs';

import { MobileNavigation } from '@/components/shared/docs/MobileNavigation';
import NavDropdown from '@/components/shared/docs/NavDropdown';
import { SearchBar } from '@/components/shared/docs/SearchBar';

import { navbarNavData } from '@/data/docs/navbar';

export const NavigationHeaderDesktop = ({ pageProps, navigationData }) => {
  let [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0);
    }
    onScroll();
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <header
      className={clsx(
        'sticky top-0 z-[5000] flex flex-wrap items-center justify-between border-none shadow-md shadow-slate-900/5 dark:bg-slate-800 dark:shadow-none',
        isScrolled
          ? 'nav-border-animated-scrolled dark:bg-slate-900/95 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75'
          : 'nav-border-not-scrolled dark:bg-transparent',
      )}
    >
      <div className='container mx-auto px-4 py-4 transition-all xs:px-8 sm:px-6 lg:px-8 xl:px-12'>
        <nav className='relative hidden w-full items-center justify-start text-white lg:flex xl:justify-center'>
          <MobileNavigation />
          <div className='flex items-center gap-4 md:flex-row'>
            <Link href={constants.docsPath}>
              <span className='hover:text-gray-300'>Home</span>
            </Link>
            {navbarNavData.map((dropDownData) => (
              <NavDropdown key={dropDownData.title} dropDownData={dropDownData} />
            ))}
          </div>
          <div className='absolute right-0 flex flex-row space-x-4'>
            {/* <ThemeSelector /> */}
            {/* <div className="flex items-center">
              <Tooltip label={"Open Settings"} dir="bottom">
                <SettingsIcon
                  aria-label="open settings dialog"
                  onClick={() => setIsDialogOpen(true)}
                  role="button"
                  className="w-8 h-8 cursor-pointer "
                />
              </Tooltip>
              <SettingsDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
              />
            </div> */}
            <SearchBar />
          </div>
        </nav>
        <nav className='relative flex w-full items-center justify-start text-white lg:hidden lg:justify-center'>
          <MobileNavigation navigationData={navigationData} />
          {/* <div className="flex items-center gap-4 md:flex-row">
            <NavDropdown dropDownData={komodefiAPIDropdownData} />
            <NavDropdown dropDownData={komodefiDropdownData} />
            <NavDropdown dropDownData={antaraDropdownData} />
            <NavDropdown dropDownData={smartchainDropdownData} />
          </div> */}
          <div className='absolute right-0'>
            {/* <ThemeSelector /> */}

            <SearchBar />
          </div>
        </nav>
      </div>
    </header>
  );
};
