import { Fragment, useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { constants } from 'constants.mjs';

import { CloseIcon, Logo, MenuIcon } from '@/components/icons/ElementIcons';
import { LeftSideBar } from '@/components/shared/docs/LeftSideBar';

import { inter } from '@/styles/fonts';

import { navbarNavData } from '@/data/docs/navbar';

export const MobileNavigation = ({ navigationData }: { navigationData: any }) => {
  let router = useRouter();
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    function onRouteChange() {
      setIsOpen(false);
    }

    router.events.on('routeChangeComplete', onRouteChange);
    router.events.on('routeChangeError', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
      router.events.off('routeChangeError', onRouteChange);
    };
  }, [router, isOpen]);

  return (
    <div className={`relative mr-6 flex lg:hidden`}>
      <button
        type='button'
        onClick={() => setIsOpen(true)}
        className='relative'
        aria-label='Open navigation'
      >
        <MenuIcon className='h-6 w-6 stroke-slate-500' />
      </button>
      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        className='fixed inset-0 z-[10000] flex items-start bg-slate-900/50 pr-10 backdrop-blur lg:hidden'
        aria-label='Navigation'
      >
        <Dialog.Panel
          className={`relative max-h-full w-full max-w-xs overflow-y-auto bg-white px-4 pb-5 pt-5 font-sans dark:bg-brand-dark sm:px-6`}
        >
          <div className='relative mb-4 flex items-center'>
            <Link href='/' className='stroke-slate-500' aria-label='Home page'>
              <Logo />
            </Link>
            <button
              type='button'
              className='absolute right-0'
              onClick={() => setIsOpen(false)}
              aria-label='Close navigation'
            >
              <CloseIcon className='h-6 w-6 stroke-slate-500' />
            </button>
          </div>
          <div className='relative flex flex-col items-start justify-start'>
            <Link href='/' className='py-2'>
              <span className='rounded-md bg-opacity-20 text-sm font-medium text-white hover:text-gray-300'>
                Home
              </span>
            </Link>
            {/* {navbarNavData.map((dropDownData) => (
              <NavDropdown
                key={dropDownData.title}
                dropDownData={dropDownData}
              />
            ))} */}
            <MobileNavItems dropDownData={navbarNavData} />
          </div>
          <LeftSideBar
            classNaming='mt-5 rounded-lg bg-slate-900 p-4'
            navigationData={navigationData}
          />
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

const MobileNavItems = ({ dropDownData }: any) => {
  const [selectedDropDownElTitle, setselectedDropDownElTitle] = useState(dropDownData[0].title);

  return (
    <div className='relative w-full'>
      <Listbox value={selectedDropDownElTitle} onChange={setselectedDropDownElTitle}>
        <div className='relative mt-1'>
          <Listbox.Button className='relative w-full cursor-default rounded-lg bg-slate-900 py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
            <span className='block truncate'>{selectedDropDownElTitle}</span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute z-[6000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-slate-900 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
              {dropDownData.map((dropDownDataEl: any, idx: any) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-cyan-100 text-cyan-900' : 'text-cyan-300'
                    }`
                  }
                  value={dropDownDataEl.title}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {dropDownDataEl.title}
                      </span>
                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-cyan-900'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <div className='mt-2 rounded-lg bg-slate-700 shadow-md'>
        {dropDownData
          .filter((dropDownDataEl: any) => selectedDropDownElTitle === dropDownDataEl.title)[0]
          .items.map((item: any, index: number) => {
            return (
              <Link key={index} href={constants.docsPath + item.link}>
                <button className='block w-full cursor-pointer py-2 pl-3 text-left text-white focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                  {item.title}
                </button>
              </Link>
            );
          })}
      </div>
    </div>
  );
};
