import React, { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { constants } from 'constants.mjs';

import { LeftSideBarCategory } from '@/components/shared/docs/LeftSideBarCategory';
import Tooltip from '@/components/shared/docs/Tooltip';

// interface SidebarProps {
//   links: { label: string; to: string }[];
//   height: { label: string; to: string }[];
// }
export const LeftSideBar: any = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  activeSection,
  classNaming,
  navigationData,
}: any) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  //const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [prevActiveSection, setPrevActiveSection] = useState(null);
  const router = useRouter();
  const currentPath = router.pathname;

  function stripSlashFromEnd(str: string) {
    return str.replace(/\/+$/, '');
  }
  useEffect(() => {
    navigationData.forEach((obj: any) => {
      let shouldExpandObj = obj.links.some((item: any) => {
        return stripSlashFromEnd(constants.docsPath + item.href) === stripSlashFromEnd(currentPath);
      });
      if (shouldExpandObj) {
        setExpandedSection(obj.title);
      }
    });
  }, [navigationData, currentPath]);

  const toggleSection = (sectionTitle: string) => {
    if (expandedSection === sectionTitle) {
      setExpandedSection(null); // collapse the section if it's already expanded
    } else {
      setExpandedSection(sectionTitle); // expand the new section
    }
  };

  useEffect(() => {
    //const hash = router.asPath.split("#")[1];
    if (activeSection && prevActiveSection) {
      const element = document.getElementById(`${prevActiveSection}`);
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isSidebarCollapsed]);

  const toggleNavCollapse = () => {
    setPrevActiveSection(activeSection);
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  //const classNameProp = pageProps?.classNaming || "";

  // if (!navigationData.length) return null;
  const [activeLinkEl, setActiveLinkEl] = useState<HTMLAnchorElement | null>(null); // state to store active link element

  /* const [timePassed, setTimePassed] = useState(false); // State to toggle visibility of the list
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimePassed(true);
    }, 1000); // 1 second

    return () => clearTimeout(timer);
  }, []);

  //TODO:Needs polish
useEffect(() => {
    if (timePassed) {
      // TODO: use another metric instead of timepassed
      // console.log(activeLinkEl?.parentElement?.offsetTop);

      // console.log("activeLinkEl", activeLinkEl?.offsetTop);
      //test  in http://localhost:3000/en/docs/antara/tutorials/pegs-module-creator-tutorial/#tutorial-overview
      let div = document.getElementById("sidebar-scroll-div");
      // console.log("div", div ? div.scrollHeight : "");
      // console.log("div", div ? div.clientHeight : "");
      let nav = document.getElementById("sidebar-nav");
      // console.log("nav", nav ? nav.scrollHeight : "");
      // console.log("nav", nav ? nav.clientHeight : "");

      let leftSideBarHeight = div?.clientHeight;
      let activeElTop = activeLinkEl?.parentElement?.offsetTop;
      let sidebarFullHeight = nav?.scrollHeight;
      // console.log("leftSideBarHeight", leftSideBarHeight);
      // console.log("activeElTop", activeElTop);
      // console.log("sidebarFullHeight", sidebarFullHeight);
      if (leftSideBarHeight && activeElTop && sidebarFullHeight && div) {
        //console.log("div.scrollTop", activeElTop - leftSideBarHeight);
        div.scrollTop = activeElTop - leftSideBarHeight + leftSideBarHeight / 2;
      }
    }
  }, [timePassed, activeLinkEl]);*/

  return (
    <div className={`relative z-[1100] lg:sticky lg:top-[7rem] ${classNaming ? classNaming : ''}`}>
      <div
        onClick={toggleNavCollapse}
        className='relative z-[100] flex cursor-pointer items-center justify-between'
      >
        <div
          className={`${
            isSidebarCollapsed
              ? 'absolute right-[-15px] top-[0px]'
              : 'absolute right-[-12px] top-[0px]'
          }`}
        >
          <Tooltip label={isSidebarCollapsed ? 'Open Sidebar' : 'Collapse Sidebar'} dir='right'>
            <ChevronLeftIcon
              className={`h-8 w-8 rounded-lg border-2 border-[#026782] bg-slate-800 text-white transition-transform hover:bg-slate-600 ${
                isSidebarCollapsed ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </Tooltip>
        </div>
      </div>
      <div
        className={`relative overflow-x-hidden py-0 lg:-ml-0.5 lg:h-[calc(100vh-7.5rem)] lg:pl-0.5 ${
          isSidebarCollapsed ? 'w-0 border-r-2 border-slate-700' : 'w-full lg:w-64 xl:w-72'
        }`}
        id='sidebar-scroll-div'
      >
        <div className={`${isSidebarCollapsed ? 'hidden' : 'block'}`}>
          <nav
            className={clsx(
              'relative h-full border-slate-700 pr-4 text-base lg:border-r-2 lg:text-sm',
              'lg:overflow-y-auto',
            )}
            id='sidebar-nav'
          >
            <ul role='list' className='space-y-4 pb-4'>
              {navigationData.map((section: any) => (
                <LeftSideBarCategory
                  section={section}
                  key={section.title}
                  isExpanded={expandedSection === section.title}
                  onToggle={() => toggleSection(section.title)}
                  activeLinkEl={activeLinkEl}
                  setActiveLinkEl={setActiveLinkEl}
                />
              ))}
            </ul>
          </nav>
        </div>
      </div>{' '}
    </div>
  );
};
