import { AnchorHTMLAttributes, Fragment, useEffect, useRef, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import { Dialog, DialogContent, DialogOverlay } from '@reach/dialog';
import clsx from 'clsx';

import { InfoIcon } from '@/components/icons/ElementIcons';
import { Heading } from '@/components/mdx/Heading';
import { OptimizedImageProps } from '@/components/mdx/types';

export { CodeGroup, UseInjectScript, Code as code, Pre as pre } from '@/components/mdx/Code';
export { TocForIndex } from '@/components/mdx/TocForIndex';

export const Note = ({
  children,
  type = 'info',
  classNaming,
}: {
  children: JSX.Element;
  type: 'error' | 'info' | 'warning';
  classNaming?: string;
}) => {
  return (
    <div
      className={clsx(
        'my-6 flex gap-2.5 rounded-2xl border p-4 leading-6',
        {
          'bg-primary-50/50 text-primary-900 dark:bg-primary-500/5 dark:text-primary-200':
            type === 'info',
        },
        {
          'bg-[#ef5350] text-white prose-a:text-white dark:bg-[#c62828]': type === 'error',
        },
        {
          'bg-[#ffb74d] dark:bg-[#461414] dark:text-white': type === 'warning',
        },
        classNaming,
      )}
    >
      <InfoIcon
        className={clsx(
          'mt-1 h-4 w-4 flex-none stroke-white dark:fill-primary-200/20 dark:stroke-primary-200',
          {
            'fill-primary-500': type === 'info',
          },
          {
            'fill-[#c62828]': type === 'error',
          },
          {
            'fill-[#461414]': type === 'warning',
          },
        )}
      />
      <div className='[&>:first-child]:mt-0 [&>:last-child]:mb-0'>{children}</div>
    </div>
  );
};

export function Row({ children }: { children: JSX.Element }) {
  return (
    <div className='grid grid-cols-1 items-start gap-x-16 gap-y-10 xl:max-w-none xl:grid-cols-2'>
      {children}
    </div>
  );
}

export function Col({ children, sticky = false }: { children: JSX.Element; sticky: boolean }) {
  return (
    <div
      className={clsx(
        '[&>:first-child]:mt-0 [&>:last-child]:mb-0',
        sticky && 'xl:sticky xl:top-24',
      )}
    >
      {children}
    </div>
  );
}

const Anchor = (href: string, props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  <Link href={href} {...props}></Link>;
};

export const h2 = (props: any) => {
  return (
    <Heading {...props} level={2}>
      {props.children}
    </Heading>
  );
};
export const h3 = (props: any) => {
  return (
    <Heading {...props} level={3}>
      {props.children}
    </Heading>
  );
};
export const h4 = (props: any) => {
  return (
    <Heading {...props} level={4}>
      {props.children}
    </Heading>
  );
};
export const h5 = (props: any) => {
  return (
    <Heading {...props} level={5}>
      {props.children}
    </Heading>
  );
};
export const h6 = (props: any) => {
  return (
    <Heading {...props} level={6}>
      {props.children}
    </Heading>
  );
};

export const OptimizedImage = ({
  src,
  alt,
  title,
  style,
  classNaming,
  noZoom,
  otherProps,
}: OptimizedImageProps) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeModalIfClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', closeModalIfClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeModalIfClickOutside);
    };
  }, []);
  return (
    <>
      <ExportedImage
        loading='lazy'
        src={src}
        alt={alt || title || 'Image in Komodo docs'}
        placeholder='empty'
        title={title || alt || 'Image in Komodo docs'}
        className={classNaming ? classNaming : 'mx-auto w-1/2 cursor-pointer'}
        onClick={openModal}
        style={{
          //...
          ...style,
        }}
        {...otherProps}
      />
      {!noZoom && (
        <DialogOverlay isOpen={isOpen} onDismiss={closeModal} dangerouslyBypassFocusLock>
          <DialogContent className=''>
            <div className='fixed inset-0 bg-black bg-opacity-50' />
            <div className='fixed inset-0'>
              <div className='flex min-h-full items-center justify-center text-center'>
                <div
                  ref={ref}
                  className='flex h-[90vh] w-auto transform items-center justify-center bg-slate-800 bg-opacity-90 align-middle shadow-xl transition-all xs:h-[98vh]'
                  onClick={closeModal}
                >
                  <ExportedImage
                    loading='lazy'
                    src={src}
                    alt={alt || title || 'Image in Komodo docs'}
                    placeholder='empty'
                    title={title || alt || 'Image in Komodo docs'}
                    className={`${classNaming} h-full w-full cursor-pointer object-contain`}
                    style={{
                      ...style,
                    }}
                    {...otherProps}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        </DialogOverlay>
      )}
    </>
  );
};

export const ThemeSwitchImage = ({
  onLightMode,
  onDarkMode,
}: {
  onLightMode: OptimizedImageProps;
  onDarkMode: OptimizedImageProps;
}) => {
  return (
    <>
      <OptimizedImage
        {...onLightMode}
        classNaming={onDarkMode?.classNaming + ' hidden dark:block'}
      />
      <OptimizedImage {...onDarkMode} classNaming={onDarkMode?.classNaming + ' dark:hidden'} />
    </>
  );
};

export const CollapsibleSection = ({
  children,
  collapsedText = 'Show',
  expandedText = 'Hide',
}: {
  children: JSX.Element;
  collapsedText?: string;
  expandedText?: string;
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <button
        aria-label='collapsible section'
        onClick={() => setVisible(!visible)}
        className='hover:brand-green/40 mb-3 block rounded bg-brand-light px-2 py-0.5 text-white dark:bg-brand-green dark:text-slate-200'
      >
        {visible ? expandedText : collapsedText}
      </button>
      {visible ? children : null}
    </div>
  );
};

export const BulletPoints = ({
  imageProps,
  title,
  desc,
}: {
  imageProps: OptimizedImageProps;
  title: string;
  desc: string | JSX.Element;
}) => {
  return (
    <div className='relative mt-5 flex flex-col items-center rounded-xl border border-slate-200 px-3 py-2 dark:border-brand-light md:flex-row'>
      {/* <div> */}
      <OptimizedImage
        {...imageProps}
        classNaming={imageProps.classNaming ? imageProps.classNaming : ''}
      />
      {/* </div> */}
      <div className='mx-5'>
        <h3 className='my-2'>{title}</h3>
        {typeof desc === 'string' ? <p className=''>{desc}</p> : desc}
      </div>
    </div>
  );
};

export const DevComment = ({ children }: { children: JSX.Element }) => {
  return null;
};
