import clsx from 'clsx';

import { hack, inter, lexend } from '@/styles/fonts';

export function Prose({ children, classNaming }: { children: JSX.Element; classNaming?: string }) {
  return (
    <div
      className={clsx(
        classNaming,
        `prose-slate prose max-w-none font-sans dark:prose-invert dark:text-slate-300 ${inter.variable}`,
        // headings
        `prose-headings:scroll-mt-28 prose-headings:font-display ${lexend.variable} break-words prose-headings:mt-4 prose-headings:font-normal lg:prose-headings:mt-6 lg:prose-headings:scroll-mt-[8.5rem]`,
        // lead
        'prose-lead:text-slate-500 dark:prose-lead:text-slate-400',
        // links
        'prose-a:font-semibold prose-a:text-slate-400 dark:prose-a:text-sky-400',
        // link underline
        'prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px]',
        // pre
        `prose-pre:rounded-xl prose-pre:bg-slate-900 prose-pre:font-mono ${hack.variable} prose-pre:shadow-lg dark:prose-pre:bg-slate-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-slate-300/10`,
        // hr
        'dark:prose-hr:border-slate-800',
      )}
    >
      {children}
    </div>
  );
}
