import { useCallback, useEffect, useState } from 'react';

export const useTOC_Section_Indicator = (
  sections: {
    title: string;
    id: string;
    children: { title: string; id: string }[];
  }[] = [],
) => {
  const [activeSection, setActiveSection] = useState<string | undefined>(sections[0]?.id);

  const grabSectionsFromDOM = useCallback(() => {
    return sections
      .flatMap((element) => [element.id, ...element.children.map((child) => child.id)])
      .map((id) => {
        const element = document.getElementById(id);
        if (!element) return;
        const scrollMt = parseFloat(window.getComputedStyle(element).scrollMarginTop);
        const top =
          window.scrollY +
          element.getBoundingClientRect().top -
          (scrollMt + Math.floor(window.innerHeight / 5));

        return { id, top };
      });
  }, [sections]);

  useEffect(() => {
    function onPageScroll() {
      if (!!sections.length) {
        const toc = grabSectionsFromDOM();
        const top = window.scrollY;
        let current = toc[0]?.id;

        for (let content of toc) {
          if (content)
            if (top >= content.top) {
              current = content.id;
            } else {
              break;
            }
        }

        setActiveSection(current);
      }
    }
    window.addEventListener('scroll', onPageScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onPageScroll, true);
    };
  }, [grabSectionsFromDOM, sections.length]);

  return activeSection;
};
