import { useEffect, useRef, useState } from 'react';

import Link from 'next/link';
//import { useHandleNavigation } from "@/store/navigation";
import { useRouter } from 'next/router';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { MDXProvider } from '@mdx-js/react';
import clsx from 'clsx';
import { constants } from 'constants.mjs';

import * as mdxComponents from '@/components/mdx';
import { GitHubIcon } from '@/components/icons/ElementIcons';
import { CustomLink } from '@/components/override-mdx/Link';
import { MetaHead } from '@/components/shared/MetaHead';
import { SafeHydrate } from '@/components/shared/SafeHydrate';
import StructuredData from '@/components/shared/StructuredData';
import { Breadcrumbs } from '@/components/shared/docs/Breadcrumb';
import { Contributors } from '@/components/shared/docs/Contributors';
import { Footer } from '@/components/shared/docs/Footer';
import { LeftSideBar } from '@/components/shared/docs/LeftSideBar';
import { NavigationHeaderDesktop } from '@/components/shared/docs/NavigationHeaderDesktop';
import { Prose } from '@/components/shared/docs/Prose';
import { ScrollToTop } from '@/components/shared/docs/ScrollToTop';
import { SuperNav } from '@/components/shared/docs/SuperNav';
import { Hero } from '@/components/shared/docs/homepage/Hero';

import { hack, inter, lexend, montserrat } from '@/styles/fonts';

import fileData from '@/data/docs/_fileData.json';
import authorsData from '@/data/docs/authors.json';
import { sidebarNavData } from '@/data/docs/sidebar';
import { useTOC_Section_Indicator } from '@/hooks/docs/useTOC_Section_Indicator';
import { calcMetaValues } from '@/shared-functions/calcMetaValues';
import { atomKeys } from '@/store/atomKeys';
import { SidebarNavigationDataType, SidebarNavigationRouteType } from '@/store/navigation/types';

const customComponents = { a: CustomLink, ...mdxComponents };

const komodian_knight = {
  username: 'komodoplatform',
  commit_emails: [],
  socials: {
    twitter: 'komodoplatform',
    linkedin: '',
  },
  id: 0,
  avatar_url: '',
  image: 'komodian-knight.png',
};

const komodian_knight_data = {
  name: 'komodian_knight',
  username: komodian_knight.username,
  avatar_url: '',
  socials: komodian_knight.socials,
  image: komodian_knight.image,
};

// const allAuthors: Record<string, User> = authorsData;
const authorsArray = Object.values(authorsData);

export const DocsLayout = ({ children, pageProps }: { children: JSX.Element; pageProps: any }) => {
  const router = useRouter();
  const route = router.pathname as keyof typeof fileData;
  const metaValues: any = calcMetaValues(pageProps, router);
  const { finTitle, finDesc, canonicalUrl, previewImgUrl } = metaValues;
  const modRoute = route.replace(constants.docsPath, '');
  const fileData_route = fileData[modRoute as keyof typeof fileData];
  const allContributorsArray: any = fileData_route?.contributors
    ? fileData_route?.contributors.map((contributor) => getAuthorData(contributor))
    : [komodian_knight_data];
  const allContributors = allContributorsArray.filter((item: any, index: number) => {
    //filter repeated entries
    return (
      index ===
      allContributorsArray.findIndex((obj: any) => {
        return obj.username === item.username;
      })
    );
  });
  const lastContributor = fileData_route?.lastContributor
    ? getAuthorData(fileData_route?.lastContributor)
    : komodian_knight;

  ////////////////////////

  const isHomePage = router.pathname === '/en/docs';
  const activeSection = useTOC_Section_Indicator(pageProps.sections);
  const [navigationData, setNavigationData] = useState(Object.values(sidebarNavData[0])[0]);

  useEffect(() => {
    //sets sidebar data on page change
    sidebarNavData.forEach((sidebarObj: SidebarNavigationRouteType) => {
      if (compareTitleLinksAndHrefs(sidebarObj, route)) {
        setNavigationData(Object.values(sidebarObj)[0]);
      }
    });
  }, [route]);

  const isActive = (id: string) => {
    if (id === activeSection) return true;
    else return false;
  };

  // const { findAndSetNavigationDataForCurrentPage, navigationData } =
  //   useHandleNavigation();
  // useEffect(() => {
  //   findAndSetNavigationDataForCurrentPage(router.pathname);
  // }, [findAndSetNavigationDataForCurrentPage, router.pathname]);

  const techArticleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'TechArticle',
    headline: finTitle,
    description: finDesc,
    image: previewImgUrl,
    author: allContributors.map((contributor: any) => {
      return {
        '@type': 'Person',
        name: contributor.username,
        url: `https://github.com/${contributor.username}`,
      };
    }),
    maintainer: {
      '@type': 'Organization',
      name: constants.orgName,
      url: constants.domain,
      email: constants.supportEmail,
      logo: constants.domain + constants.pngLogo,
      sameAs: Object.values(constants.socialLinks),
      description: constants.orgDesc,
    },
    dateCreated: fileData_route ? fileData_route.dateCreated : '',
    dateModified: fileData_route ? fileData_route.dateModified : '',
  };

  const organizationStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: constants.orgName,
    // image: "", TODO:Find and use appropriate image
    url: constants.domain,
    email: constants.supportEmail,
    logo: constants.domain + constants.pngLogo,
    sameAs: Object.values(constants.socialLinks),
    description: constants.orgDesc,
  };

  const refOnThisPage = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnThisPageIfClickOutside = (event: MouseEvent) => {
      if (refOnThisPage.current && !refOnThisPage.current.contains(event.target as Node)) {
        setShowList(false);
      }
    };
    document.addEventListener('mousedown', closeOnThisPageIfClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeOnThisPageIfClickOutside);
    };
  }, []);

  function flattenNavigation(navData: any) {
    let flatNav: any = [];
    navData.forEach((item: any) => {
      if (item.titleLink) {
        flatNav.push({ title: item.title, href: item.titleLink });
      }
      if (item.links && item.links.length > 0) {
        flatNav = flatNav.concat(item.links);
      }
    });
    return flatNav;
  }

  function getPrevNextPages(currentPath: string) {
    const flatNav = flattenNavigation(navigationData);
    const currentIndex = flatNav.findIndex((item: any) => {
      return constants.docsPath + item.href === currentPath;
    });
    if (currentIndex === -1) {
      return { prev: null, next: null };
    }

    const prevPage = currentIndex > 0 ? flatNav[currentIndex - 1] : null;
    const nextPage = currentIndex < flatNav.length - 1 ? flatNav[currentIndex + 1] : null;

    return { prevPage, nextPage };
  }
  const currentPath = router.pathname + '/';
  const { prevPage, nextPage } = getPrevNextPages(currentPath);
  const [showList, setShowList] = useState(false); // State to toggle visibility of the list

  const toggleList = () => {
    setShowList(!showList); // Toggle the visibility of the list
  };

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  /*
  // removing the "hash changes in address bar on scroll" feature because of these bugs.
  //https://github.com/vercel/next.js/issues/29252
  //https://github.com/vercel/next.js/issues/37362
  //there are some workarounds, but they are hacky, will make ux worse on the site

  const [fiveSecondsPassed, setFiveSecondsPassed] = useState(false); // State to toggle visibility of the list

  useEffect(() => {
    const timer = setTimeout(() => {
      setFiveSecondsPassed(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Only update the hash if there is an active section and 5 seconds passed after loading the site. time delay to prevent the hash from being updated on page load and causing the page to jump to the section
    if (activeSection && fiveSecondsPassed) {
      const hash = `#${activeSection}`;
      window.history.replaceState(null, "", hash);

      //TODO: make right sidebar active item visible on pageload. test in http://localhost:3000/en/docs/antara/tutorials/advanced-series-5/

      // let activeSectionLinkEl = document.getElementById(
      //   `link-${activeSection}`
      // );

      // let rightSidebarNav = document.getElementById("right-sidebar-nav");

      // let rightSidebarDiv = document.getElementById("right-sidebar-div");

      // let activeSectionLinkElTop =
      //   activeSectionLinkEl?.parentElement?.offsetTop;
      // let rightSideBarHeight = rightSidebarDiv?.clientHeight;
      // let sidebarFullHeight = rightSidebarNav?.scrollHeight;
      // if (
      //   activeSectionLinkElTop &&
      //   rightSideBarHeight &&
      //   sidebarFullHeight &&
      //   rightSidebarDiv
      // ) {
      //   rightSidebarDiv.scrollTop =
      //     activeSectionLinkElTop + rightSideBarHeight * 1.5 - sidebarFullHeight;
      // }
    }
  }, [activeSection, fiveSecondsPassed]);*/

  /* Seems unnecessary. desired behavior seems to be default
  // To make the long method names in the API methods page break at the end of table cell instead of overflowing
  useEffect(() => {
    const apiMethodsPath = "/komodo-defi-framework/api/";
    if (apiMethodsPath === currentPath) {
      const style = document.createElement("style");
      style.innerHTML = `
        table td {
          word-break: break-all;
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [currentPath]);*/

  return (
    <SafeHydrate>
      <div className={`w-full`}>
        <MetaHead metaValues={metaValues} />
        <StructuredData
          data={techArticleStructuredData}
          customKey={'techarticle-structured-data'}
        />
        {/* This is redundant as the techarticle already contains the organization item
      <StructuredData
        data={organizationStructuredData}
        customKey={"organization-structured-data"}
      /> */}
        <SuperNav />
        <NavigationHeaderDesktop pageProps={pageProps} navigationData={navigationData} />
        {isHomePage && <Hero />}
        <div className='relative w-full'>
          <div className='container mx-auto flex px-4 xs:px-8 sm:px-6 lg:px-8 xl:px-12'>
            <div className='hidden lg:relative lg:block lg:flex-none'>
              {/* <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-800" /> */}
              {/* <div className="absolute bottom-0 right-0 hidden w-px h-12 top-16 bg-gradient-to-t from-slate-800 dark:block" /> */}
              {/* <div className="absolute bottom-0 right-0 hidden w-px top-28 bg-slate-800 dark:block" /> */}
              <LeftSideBar
                navigationData={navigationData}
                isSidebarCollapsed={isSidebarCollapsed}
                setIsSidebarCollapsed={setIsSidebarCollapsed}
                activeSection={activeSection}
              />
            </div>
            {/* <div className="flex flex-col w-full mx-auto xl:flex-row lg:ml-auto lg:mr-0"> 
          2xlm:ml-[calc((1728px-18rem-800px-14rem)/2)] 3xl:ml-[calc((1920px-18rem-700px-14rem)/2)] 4xl:ml-[calc((2000px-18rem-800px-14rem)/2)]*/}

            <div
              className={`mx-auto flex w-full flex-col lg:ml-auto lg:mr-0 xl:ml-auto xl:flex-row ${
                isSidebarCollapsed ? '' : 'xlm:ml-12 2xl:ml-12 3xl:ml-48'
              } `}
            >
              {pageProps?.sections?.length > 0 && (
                <div
                  className='sticky top-[58px] z-[1000] w-full lg:top-[74px] lg:pl-8 xl:hidden'
                  ref={refOnThisPage}
                >
                  <div className='max-h-[65vh] items-center overflow-y-auto overflow-x-hidden rounded-lg border border-[#08081c] pr-4 dark:bg-slate-800'>
                    <div className='flex w-full flex-row items-center justify-between py-1'>
                      <div
                        className='my-2 ml-4 flex cursor-pointer flex-row items-center rounded-lg border-2 border-[#026782] p-1'
                        onClick={toggleList}
                      >
                        <h2 className='font-display text-sm font-medium text-slate-900 dark:text-white'>
                          On this page
                        </h2>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          aria-hidden='true'
                          className='h-5 w-5 text-white'
                          aria-controls='smart-chain-setup-panel'
                        >
                          <path
                            fillRule='evenodd'
                            d='M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      </div>
                      <Link
                        aria-label='Edit this page on github'
                        href={`${constants.contentRepo}/tree/dev/src/pages${
                          router.pathname === '/' ? '' : router.pathname
                        }/index.mdx`}
                        className='absolute right-4 rounded-lg border-2 border-[#026782] bg-slate-800 p-2'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <GitHubIcon className='h-4 w-4 fill-slate-400 group-hover:fill-slate-500 dark:group-hover:fill-slate-400' />
                      </Link>
                    </div>
                    {showList && (
                      <ol
                        role='list'
                        className='mb-4 ml-4 space-y-3 break-words text-sm text-slate-500 dark:text-slate-400'
                      >
                        {pageProps?.sections.map(
                          (section: {
                            title: string;
                            id: string;
                            children: { title: string; id: string }[];
                          }) => (
                            <li key={section.id}>
                              <Link
                                href={`#${section.id}`}
                                scroll={false}
                                className={
                                  isActive(section.id)
                                    ? 'w-full break-words text-[15px] text-sky-500'
                                    : 'w-full break-words text-[15px] text-slate-300 hover:text-slate-100'
                                }
                                onClick={() => setShowList(false)}
                              >
                                {section.title}
                              </Link>
                              {!!section.children.length && (
                                <ol role='list' className='ml-2 mt-2 space-y-2 pl-2 italic'>
                                  {section.children.map((link) => (
                                    <li key={link.id}>
                                      <Link
                                        href={`#${link.id}`}
                                        scroll={false}
                                        className={clsx(
                                          'flex w-full flex-row',
                                          isActive(link.id)
                                            ? 'text-sky-500'
                                            : 'text-slate-300 hover:text-slate-100',
                                        )}
                                        onClick={() => setShowList(false)}
                                      >
                                        <svg
                                          width='3'
                                          height='24'
                                          viewBox='0 -9 3 24'
                                          className='mr-1 overflow-visible group-hover:text-slate-600 dark:group-hover:text-slate-500 2xl:mr-2'
                                        >
                                          <path
                                            d='M0 0L3 3L0 6'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                          ></path>
                                        </svg>
                                        <span className='max-w-full break-words pr-2'>
                                          {link.title}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </ol>
                              )}
                            </li>
                          ),
                        )}
                      </ol>
                    )}
                  </div>
                </div>
              )}
              <main
                className={`flex w-full min-w-0 flex-col overflow-x-hidden px-4 py-16 pt-5 ${
                  isSidebarCollapsed
                    ? 'lg:min-w-[706px] xl:min-w-[calc(1280px-288px-231px-48px-48px)] 2xl:min-w-[800px]'
                    : 'lg:w-[706px] xl:w-[calc(1280px-288px-231px-48px-48px)] 2xl:w-[800px]'
                } lg:pl-8 lg:pr-0 xl:px-8 2xl:px-16`}
              >
                <Breadcrumbs pageProps={pageProps} />

                <Contributors
                  route={route}
                  lastContributor={lastContributor}
                  allContributors={allContributors}
                  fileData_route={fileData_route}
                />
                <article className='break-words pt-2'>
                  <Prose>
                    <MDXProvider components={customComponents as any}>{children}</MDXProvider>
                  </Prose>
                  <div className='mt-10 flex flex-col pt-2 text-xl text-white sm:flex-row'>
                    {prevPage ? (
                      <Link
                        href={constants.docsPath + prevPage.href}
                        className='mr-auto w-full sm:w-[45%]'
                      >
                        <button className='flex h-full w-full flex-col items-center justify-start rounded-lg border-2 border-sky-700 bg-slate-800 p-2 text-sm text-sky-500 hover:bg-slate-600 dark:text-blue-400 sm:items-start'>
                          <span className='flex flex-row items-center text-white'>
                            <ChevronDoubleLeftIcon className={`mr-1 h-4 w-4`} />
                            Previous
                          </span>
                          <span className='mt-2 text-center text-xl sm:text-left'>
                            {prevPage.title}
                          </span>
                        </button>
                      </Link>
                    ) : (
                      ''
                    )}
                    {nextPage ? (
                      <Link
                        href={constants.docsPath + nextPage.href}
                        className='ml-auto mt-4 w-full sm:mt-0 sm:w-[45%]'
                      >
                        <button className='flex h-full w-full flex-col items-center justify-start rounded-lg border-2 border-sky-700 bg-slate-800 p-2 text-sm text-sky-500 hover:bg-slate-600 dark:text-blue-400 sm:items-end'>
                          <span className='flex flex-row items-center text-white'>
                            Next
                            <ChevronDoubleRightIcon className={`ml-1 h-4 w-4`} />
                          </span>
                          <span className='mt-2 text-center text-xl sm:text-right'>
                            {nextPage.title}
                          </span>
                        </button>
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                  <Footer />
                  <ScrollToTop />
                </article>
              </main>

              <div
                className='relative ml-4 hidden overflow-x-hidden border-slate-700 xl:sticky xl:top-[7rem] xl:ml-0 xl:block xl:max-h-[calc(100vh-8.5rem)] xl:flex-none xl:overflow-y-auto'
                id='right-sidebar-div'
              >
                <nav
                  aria-labelledby='on-this-page-title'
                  className={`relative rounded-lg py-2 pl-2 pr-4 xl:w-56 2xl:w-64 2xl:py-4 2xl:pl-4 ${
                    pageProps?.sections?.length > 0 ? 'bg-slate-800' : ''
                  }`}
                  id='right-sidebar-nav'
                >
                  <Link
                    aria-label='Edit this page on github'
                    href={`${constants.contentRepo}/tree/dev/src/pages${
                      router.pathname === '/' ? '' : router.pathname.replace('/en/docs', '')
                    }/index.mdx`}
                    className='flex w-[120px] flex-row items-center justify-between rounded-lg border-2 border-sky-700 bg-slate-800 p-2 text-sm text-sky-500 hover:bg-slate-600 dark:text-blue-400'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <GitHubIcon className='h-6 w-6 fill-slate-400 group-hover:fill-slate-500 dark:group-hover:fill-slate-400' />
                    Edit page
                  </Link>
                  {pageProps?.sections?.length > 0 && (
                    <>
                      <h2
                        id='on-this-page-title'
                        className='mt-4 font-display text-sm font-medium text-slate-900 dark:text-white'
                      >
                        On this page
                      </h2>
                      <ol
                        role='list'
                        className='mt-4 space-y-3 break-words text-sm text-slate-500 dark:text-slate-400'
                      >
                        {pageProps?.sections.map(
                          (section: {
                            title: string;
                            id: string;
                            children: { title: string; id: string }[];
                          }) => (
                            <li key={section.id}>
                              <Link
                                href={`#${section.id}`}
                                scroll={false}
                                className={
                                  isActive(section.id)
                                    ? 'w-full break-words text-[15px] text-sky-500'
                                    : 'w-full break-words text-[15px] text-slate-300 hover:text-slate-100'
                                }
                                id={`link-${section.id}`}
                              >
                                {section.title}
                              </Link>
                              {!!section.children.length && (
                                <ol role='list' className='mt-2 space-y-2 pl-2 italic'>
                                  {section.children.map((link) => (
                                    <li key={link.id}>
                                      <Link
                                        href={`#${link.id}`}
                                        scroll={false}
                                        className={clsx(
                                          'flex w-full flex-row',
                                          isActive(link.id)
                                            ? 'text-sky-500'
                                            : 'text-slate-300 hover:text-slate-100',
                                        )}
                                        id={`link-${link.id}`}
                                      >
                                        <svg
                                          width='3'
                                          height='24'
                                          viewBox='0 -9 3 24'
                                          className='overflow-visible group-hover:text-slate-600 dark:group-hover:text-slate-500 xl:mr-1 2xl:mr-2'
                                        >
                                          <path
                                            d='M0 0L3 3L0 6'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                          ></path>
                                        </svg>
                                        <span className='max-w-full break-words pr-2'>
                                          {link.title}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </ol>
                              )}
                            </li>
                          ),
                        )}
                      </ol>
                    </>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SafeHydrate>
  );
};

function getAuthorData({ name, email }: { name: string; email: string }) {
  const filteredAuthorInfo = authorsArray.filter((authorData) => {
    return authorData.commit_emails.some((authorEmail) => authorEmail === email);
  });
  const authorInfo = filteredAuthorInfo.length > 0 ? filteredAuthorInfo[0] : komodian_knight;
  return {
    name: name,
    username: authorInfo.username,
    avatar_url: authorInfo.avatar_url,
    socials: authorInfo.socials,
    image: authorInfo.image,
  };
}

function compareTitleLinksAndHrefs(obj: SidebarNavigationRouteType, currentRoute: string) {
  for (const key in obj) {
    const categories = obj[key];
    for (const category of categories) {
      if (category.titleLink) {
        if (constants.docsPath + category.titleLink === currentRoute + '/') {
          return true;
        }
      }
      if (category.links) {
        for (const link of category.links) {
          if (constants.docsPath + link.href === currentRoute + '/') {
            return true;
          }
        }
      }
    }
  }
  return false;
}
