import { constants } from 'constants.mjs';

import previewImgTimeStamps from '@/data/docs/previewImgTimeStamps.json';

export const calcMetaValues = (pageProps, router) => {
  const { query, asPath, pathname, route } = router;
  let pageLang;
  if (query && query.lang && !Array.isArray(query.lang)) {
    pageLang = query.lang;
  } else if (pathname !== '/404') {
    pageLang = asPath.split('/')[1] || 'en';
  }
  let titleDefault = constants.orgName;

  let descriptionDefault = constants.orgDesc;
  let previewImgUrl;
  let previewImgFilePath;
  if (pathname.startsWith('/en/docs')) {
    titleDefault = 'Komodo Documentation';
    descriptionDefault =
      'Read documentation for Komodo Platform: KMD blockchain, Komodo DeFi Framework, Komodo Wallet';
    previewImgFilePath =
      route === '/en/docs'
        ? previewImgTimeStamps['/']?.optImgPath?.slice(6)
        : previewImgTimeStamps[route.replace(constants.docsPath, '')]?.optImgPath?.slice(6);
  }
  if (pathname.includes('/blog/')) {
    titleDefault = `Komodo Cryptocurrency Blog | Insights on Blockchain & Crypto`;
    descriptionDefault =
      'Stay updated with the latest blockchain and cryptocurrency news. Explore the Komodo blog for info on Komodo Wallet releases, development updates, &amp; More';
  }

  if (process.env.CFP == 'yes') {
    previewImgUrl = previewImgFilePath;
  } else {
    previewImgUrl = `${constants.domain}${previewImgFilePath}`;
  }

  const finTitle = pageProps.title
    ? pageProps.title
    : pageProps.exported_h1
      ? pageProps.exported_h1
      : titleDefault;
  const finDesc = pageProps.description || descriptionDefault;
  const canonicalUrl = `${constants.domain}${route}/`;

  return { finTitle, finDesc, canonicalUrl, previewImgUrl };
};
