import { useEffect } from 'react';

import Script from 'next/script';

import { motion } from 'framer-motion';

export default function NewsletterSubscriptionForm({ device }: any) {
  return (
    <div
      id={`mlb2-281802`}
      className={`ml-form-embedContainer ml-subscribe-form ml-subscribe-form-281802 ${device}-281802`}
    >
      <div className='ml-form-align-center'>
        <div className='ml-form-embedWrapper embedForm ml-auto w-full justify-center lg:justify-start'>
          <div className='ml-form-embedBody ml-form-embedBodyDefault row-form flex flex-col justify-center sm:mx-auto lg:ml-auto lg:mr-0'>
            <div className='ml-form-embedContent'>
              <h3 className='text-lg font-bold xl:text-xl -lg:text-center -lg:text-[24px]'>
                Subscribe to newsletter
              </h3>
            </div>

            <form
              className='ml-block-form flex flex-col pt-6 lg:w-[169px] lg:justify-start lg:pt-2'
              action='https://assets.mailerlite.com/jsonp/20792/forms/52187545986925609/subscribe'
              data-code=''
              method='post'
              target='_blank'
            >
              <div className='ml-form-formContent mb-2 w-full lg:w-[169px]'>
                <div className='ml-form-fieldRow ml-last-item w-full lg:w-[169px]'>
                  <div className='ml-field-group ml-field-email ml-validate-email ml-validate-required w-full sm:flex lg:w-[169px]'>
                    <input
                      aria-label='email'
                      aria-required='true'
                      type='email'
                      className='form-control w-full rounded-[9px] border border-transparent bg-br-light-gray px-5 py-2 text-center text-base text-br-dark-gray placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 lg:text-lg'
                      data-inputmask=''
                      name='fields[email]'
                      placeholder='Your e-mail'
                      autoComplete='email'
                      required
                      title='The domain portion of the email address is invalid (the portion after the @).'
                      pattern='^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$'
                    />
                  </div>
                </div>
              </div>

              <input type='hidden' name='ml-submit' value='1' />

              <div className='ml-form-embedSubmit'>
                <motion.button
                  type='submit'
                  className={`primary flex w-full items-center justify-center rounded-[9px] border border-transparent px-5 py-2 text-base font-bold text-white shadow focus:outline-none focus:ring-2 focus:ring-inset sm:flex-shrink-0 lg:w-[169px] lg:text-lg ${device}-footer-subscribe cta-btn`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                >
                  Subscribe
                </motion.button>

                <button disabled style={{ display: 'none' }} type='button' className='loading'>
                  <div className='ml-form-embedSubmitLoad'></div>
                  <span className='sr-only'>Loading...</span>
                </button>
              </div>

              <input type='hidden' name='anticsrf' value='true' />
            </form>
          </div>

          <div className='ml-form-successBody row-success' style={{ display: 'none' }}>
            <div
              className={`ml-form-successContent mx-auto w-full text-center lg:ml-0 lg:mr-auto lg:w-[169px] lg:text-left`}
            >
              <h4>Thank you!</h4>

              <p>Please check your email to confirm subscription.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
