import Link from 'next/link';
import { useRouter } from 'next/router';

import { constants } from 'constants.mjs';
import { BreadcrumbList, WithContext } from 'schema-dts';

import StructuredData from '@/components/shared/StructuredData';

import sidebarData from '@/data/docs/sidebar.json';

export const Breadcrumbs = ({ pageProps }: any) => {
  const router = useRouter();
  const path = router.pathname;
  const currentPath = path + '/';
  const pathArray = path.split('/');
  let pathSlugArray: any = [];
  let curPath = '';
  for (let slug of pathArray) {
    curPath = curPath + slug + '/';
    //let breadCrumb = pageProps.breadcrumb?pageProps.breadcrumb:pageProps.title;
    pathSlugArray.push([
      curPath,
      slug
        .split('-')
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' ')
        .split('_')
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' '),
    ]);
  }

  pathSlugArray[pathSlugArray.length - 1] = [
    currentPath,
    findTitleForCurrentPath(sidebarData, currentPath),
  ];
  const getFilePath = (pathSlugArray: any) => {
    let content = [];
    for (let [index, arr] of pathSlugArray.entries()) {
      if (index !== 0) {
        content.push(
          index == pathSlugArray.length - 1 ? (
            <li className='border-b border-slate-500 py-2 dark:bg-slate-800/80' key={arr[0]}>
              <Link href={arr[0]} aria-current='page'>
                {arr[1]}
              </Link>
            </li>
          ) : (
            <li className='border-b border-slate-500 py-2 dark:bg-slate-800/80' key={arr[0]}>
              <Link href={arr[0]}>{arr[1]}</Link>
            </li>
          ),
        );
      }
    }
    return content;
  };

  function findTitleForCurrentPath(sidebarData: any, currentPath: any) {
    for (const key in sidebarData) {
      if (sidebarData.hasOwnProperty(key)) {
        const allSections = sidebarData[key];
        const allSectionData: any = Object.values(allSections)[0];
        for (let section of allSectionData) {
          if (constants.docsPath + section.titleLink === currentPath) {
            return section.title;
          } else if (
            section.links.some((link: any) => constants.docsPath + link.href === currentPath)
          ) {
            return section.links.filter(
              (obj: any) => constants.docsPath + obj.href === currentPath,
            )[0].title;
          }
        }
      }
    }
  }
  pathSlugArray = pathSlugArray.slice(2);
  const breadcrumbData: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
  };
  let itemListElement: any = [];
  for (let [index, arr] of pathSlugArray.entries()) {
    let path = arr[0][arr[0].length - 1] === '/' ? arr[0].slice(0, -1) : arr[0];
    let URL = constants.domain + path;
    let name = path == constants.docsPath ? 'Komodo Documentation Home' : arr[1];
    let listElement = {
      '@type': 'ListItem',
      position: index + 1,
      name: name,
      item: URL,
    };
    itemListElement.push(listElement);
  }
  breadcrumbData['itemListElement'] = itemListElement;
  return (
    <div
      className='breadcrumb-nav my-2 mr-2 inline-block items-center py-2 text-white'
      aria-label='Breadcrumb'
    >
      <ol>
        <li className='border-b border-slate-500 py-2 pl-[0.75rem] dark:bg-slate-800/80'>
          <Link href='/en/docs/' aria-label='Navigate to Home'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-5 w-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
              />
            </svg>
          </Link>
        </li>
        {path == '/' ? '' : getFilePath(pathSlugArray)}
      </ol>
      <StructuredData data={breadcrumbData} customKey={'breadcrumb-structured-data'} />
    </div>
  );
};
