import { useRef } from 'react';

import Link from 'next/link';

import { AnchorIcon } from '@/components/icons/ElementIcons';
import { Tag } from '@/components/mdx/Tag';

export const TaggedSection = ({ tag, label }: { tag?: string; label?: string }) => {
  if (!tag && !label) {
    return null;
  }

  return (
    <div className='mt-5 flex items-center gap-x-3'>
      {tag && <Tag>{tag}</Tag>}
      {tag && label && <span className='h-0.5 w-0.5 rounded-full bg-zinc-300 dark:bg-zinc-600' />}
      {label && <span className='font-mono text-xs text-zinc-400'>{label}</span>}
    </div>
  );
};

export function Anchor({ id, children }: { id?: string; children: JSX.Element }) {
  return (
    <Link href={`#${id}`} scroll={false}>
      <div className='group mb-1 flex flex-row items-center overflow-x-auto overflow-y-hidden text-inherit no-underline'>
        <span>{children}</span>
        <AnchorIcon className='my-auto ml-2 hidden h-6 w-6 stroke-zinc-500 opacity-0 transition group-hover:opacity-100 dark:stroke-zinc-400 lg:block' />
      </div>
    </Link>
  );
}

export const Heading = ({
  level = 2,
  children,
  tag,
  label,
  anchor = true,
  id = '',
  ...props
}: {
  level: number;
  children: JSX.Element;
  tag?: string;
  label?: string;
  anchor?: boolean;
  id: string;
  props: any;
}) => {
  const Component: any = `h${level}`;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <TaggedSection tag={tag} label={label} />
      <Component
        ref={ref}
        className={tag || label ? 'mt-2 scroll-mt-32' : 'scroll-mt-24'}
        id={id}
        {...props}
      >
        {anchor ? (
          <div className='not-prose'>
            <Anchor id={id}>{children}</Anchor>
          </div>
        ) : (
          children
        )}
      </Component>
    </>
  );
};
