export const FooterItem = ({ href, text, arrow }) => {
  return arrow === 'no' ? (
    <a href={href} className={`flex items-start`}>
      <p className='text-sm font-medium text-slate-300'>{text}</p>
    </a>
  ) : (
    <a href={href} className={`flex items-start`}>
      <div className='flex'>
        <span className='text-sm font-medium text-slate-300'>{text}</span>
        <img
          src='/img/atomicdex/footer-arrow-mobile.svg'
          className='ml-2 h-auto w-[10px]'
          alt='footer-arrow'
        />
      </div>
    </a>
  );
};
