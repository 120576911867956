import { constants } from 'constants.mjs';

let data = {
  title: constants.orgName,
  description: constants.orgDesc,
  root: 'https://komodoplatform.com',
  siteName: 'komodoplatform.com',
  generator: 'Nextjs',
  language: 'en-US',
  twitter: '@KomodoPlatform',
  komodoPlatformHome: 'https://komodoplatform.com/en/',
  socialUnfurlImage: '',
  defaultEmail: constants.helloEmail,
  gtmId: '',
  logo: '',
  tag: 'komodo',
  webDEXlink: 'https://app.komodoplatform.com/',
  swapWidgetUrl: 'https://swap-widget.komodoplatform.com/',
  downloadsPage: '/en/wallet/',
  downloadURL: 'https://github.com/KomodoPlatform/komodo-wallet/releases',
  apiUrl: 'https://stats-api.atomicdex.io/api/v1/',
  mobileGuidesLink: '/en/docs/komodo-wallet/mobile/',
  desktopGuidesLink: '/en/docs/komodo-wallet/desktop/',
  helloEmail: constants.helloEmail,
  social: constants.socialLinks,
  roadmap: 'https://roadmap.komodoplatform.com/',
  brandAssetsZip: '/dl/Komodo_Brand_Assets_23.zip',
  MATOMO_CONTAINER_ID: 'container_2O4bjY8S_dev_8b409699cb1da61d7daf083f',
  env: {},
};

if (process.env.NODE_ENV === 'production') {
  data.env.PRODUCTION = 'yes';
  data.MATOMO_CONTAINER_ID = 'container_2O4bjY8S';
}

if (process.env.CFP == 'yes') {
  data.root = '';
  data.env.CFP = 'yes';
  data.MATOMO_CONTAINER_ID = 'container_2O4bjY8S_dev_8b409699cb1da61d7daf083f';
}

export const siteData = data;
