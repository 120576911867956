import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { debounce } from '@/shared-functions/debounce';
import { scrollToSection } from '@/shared-functions/scrollToSection';

export const ScrollToTop = () => {
  const [showScroller, setShowScroller] = useState(false);
  const verifyScrollingCriteria = () => {
    if (window.scrollY >= 20) setShowScroller(true);
    else setShowScroller(false);
  };
  const debouncedOnScroll = debounce(verifyScrollingCriteria);
  useEffect(() => {
    window.addEventListener('scroll', debouncedOnScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', debouncedOnScroll, true);
    };
  }, []);
  return (
    <div
      onClick={() => scrollToSection({ top: 0, left: 0 })}
      role='navigation'
      aria-label='Scroll to top of the page'
      className={clsx(
        'group fixed bottom-[-10%] right-5 z-10 flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full border-2 border-sky-500 bg-white font-bold text-sky-500 opacity-50 duration-300 ease-in hover:opacity-100 dark:bg-brand-dark -sm:hidden',
        { 'bottom-[15px]': showScroller },
      )}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='h-6 w-6'
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' />
      </svg>
    </div>
  );
};
